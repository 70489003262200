import * as Sentry from '@sentry/nextjs'
import { queryClient } from '@services/query'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { getToken, useAuthState } from 'store/auth'
import { isValidJWT } from './client/patient'

const URLS_TO_IGNORE = ['https://api.getaddress.io', 'directory.spineservices.nhs.uk']

const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  timeout: +process.env.NEXT_PUBLIC_API_TIMEOUT,
  timeoutErrorMessage: 'Request timed out',
  adapter: 'fetch',
  fetchOptions: {
    cache: 'no-store'
  }
})

http.interceptors.request.use(async req => {
  if (['/user/register', '/authenticate/token/renew'].some(url => req.url.includes(url))) return req

  if (typeof window !== 'undefined') {
    const vendor = window.location.pathname.split('/')[2]
    if (vendor) {
      req.headers['x-evaro-vendor'] = vendor
    }
  }

  if (useAuthState.getState().isLogged && req.headers.Authorization !== null) {
    const token = await getToken()
    if (!token) {
      throw new Error(`No Token, cancelling request ${req.url}`)
    }
    req.headers.Authorization = `Bearer ${token}`
  }

  return req
})

http.interceptors.response.use(
  res => res.data?.data ?? res.data,
  error => {
    const status = error.response?.status
    const url: string = error.config?.url
    console.warn('HTTP API ERROR', url, error?.message)

    if (axios.isCancel(error)) return Promise.reject(error)
    // TIMEOUT
    if (error.code === 'ECONNABORTED') return Promise.reject(error)
    // NO STATUS OR URL NETWORK ISSUE
    if (!status || !url) return Promise.reject(error)

    const isFEError = status >= 400 && status < 500

    if (status === 403) {
      console.warn('403 Error, logging out')
      useAuthState.getState().logOut()
      queryClient.removeQueries({ queryKey: ['user'], type: 'all' })
      // RETURN TO DO NOT CAPTURE INVALID TOKENS IN SENTRY
      return Promise.reject(error.response?.data?.data || error.response?.data)
    }

    try {
      const ignoreUrl = URLS_TO_IGNORE.some(e => url.includes(e))
      if (ignoreUrl) return Promise.reject(error)
      const body = error.config.data

      const isJsonHeaders = error.config.headers?.['Content-Type'] === 'application/json'
      const parcedBody = body && isJsonHeaders ? JSON.parse(body) : body

      const hashedUrl = url
        .split('/')
        .map(e => (e.length === 30 ? ':hash' : e))
        .join('/')
      error.name = 'API Error'
      error.message = `URL:${hashedUrl} ${error.message}`

      const name = `API Error : ${error.config.method.toUpperCase()} ${hashedUrl} ${status}`
      Sentry.captureMessage(isFEError ? name : error, {
        level: isFEError ? 'debug' : 'error',
        contexts: {
          http_error: {
            hasJWT: !!error.config.headers?.Authorization,
            isValidJWT: !!isValidJWT(error.config.headers?.Authorization as string),
            request: {
              headers: error.config.headers,
              body: parcedBody
            },
            response: {
              headers: error.response?.headers,
              body: error.response?.data,
              status: error.response?.status
            },
            retryCount: error.config['axios-retry']?.retryCount,
            code: error.code,
            ALL_DATA: error
          }
        },
        tags: {
          API_RESPONSE_MSG: error.response?.data?.message ?? null
        }
      })
    } catch (e) {
      console.error('Error in HTTP response error try method', e)
      Sentry.captureException(e)
      Sentry.captureMessage(error.name, isFEError ? 'debug' : 'error')
    }

    return Promise.reject(error.response?.data?.data || error.response?.data)
  }
)

axiosRetry(http, {
  retries: 3,
  shouldResetTimeout: true,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition(error) {
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.code === 'ECONNABORTED'
  }
  // onRetry(retryCount, error, requestConfig) {
  //   const hashedUrl = requestConfig.url.split('/').map(e => e.length === 30 ? ':hash' : e).join('/')

  //   if (retryCount === 1) {
  //     Sentry.captureMessage('API Retry attempt', {
  //       level: 'debug',
  //       contexts: {
  //         http_error: {
  //           ...error
  //         }
  //       },
  //       tags: {
  //         API_URL: `${error.config.method.toUpperCase()} ${hashedUrl}`,
  //       }
  //     })
  //   }
  // },
})

export default http
